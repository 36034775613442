import React from 'react';
import styled from 'styled-components';

const OrContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: Center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const OrText = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.2px;
  text-align: center;
  color: ${(props) => props.theme.colors.muted};
  width: 55px;
`;

const OrDivider = styled.div`
  border: solid 1px ${(props) => props.theme.colors.grayLight};
  flex: 1;
`;

const Divider: React.FC = () => {
  return (
    <OrContainer>
      <OrDivider />
      <OrText>OR</OrText>
      <OrDivider />
    </OrContainer>
  );
};

export default Divider;
