import { routes } from 'app/config';
import {
  linkedinSuccess,
  login,
  requestRemindPassword,
  requestResetPassword,
  setLoginError,
  setLoginSuccess,
  setResetPasswordModal,
} from 'app/store/modules/auth/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { push } from 'connected-react-router';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import LoginForm from './login-form';

export interface SubmitPayload {
  username: string;
  password: string;
}

interface DispatchProps {
  onSignUpClick: () => void;
  onSubmit: (payload: SubmitPayload) => void;
  clearLoginError: () => void;
  clearLoginSuccess: () => void;
  remind: (data: { email: string }) => any;
  reset: (data: {
    password: string;
    recoveryToken: string;
    email: string;
  }) => any;
  linkedinSuccessAction: () => any;
  setResetPasswordModalOpen: (val: boolean) => any;
}

interface StateProps {
  error: string;
  success: string;
}
interface IOwnProps {
  isModal?: boolean;
  modalSingupCallback?: () => void;
}

export type IProps = StateProps & DispatchProps & IOwnProps;
export default withApollo<IOwnProps>(
  connect<StateProps, DispatchProps, IWithApolloClient & IOwnProps, IStore>(
    (state) => ({
      error: state.auth.loginError,
      success: state.auth.loginSuccess,
    }),
    (dispatch, ownProps) => {
      return {
        onSignUpClick: () => dispatch(push(routes.get_started)),
        onSubmit: (data) =>
          dispatch(
            login({
              ...data,
              isModal: ownProps.isModal,
              client: ownProps.client,
            })
          ),
        clearLoginError: () => dispatch(setLoginError('')),
        remind: (data) => dispatch(requestRemindPassword(data)),
        clearLoginSuccess: () => dispatch(setLoginSuccess('')),
        reset: (data) => dispatch(requestResetPassword(data)),
        linkedinSuccessAction: () =>
          dispatch(linkedinSuccess({ client: ownProps.client })),
        setResetPasswordModalOpen: (val: boolean) =>
          dispatch(setResetPasswordModal(val)),
      };
    }
  )(LoginForm)
);
