import { startCase } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps, withRouter } from 'react-router';

import { routes } from 'app/config';
import { toTitleCase } from 'app/store/modules/global/actions';

const productionRootUrl = 'https://www.pipelinepharma.com';
export const marketingUrlIncludesString1 = 'manufacturer-117186';
export const marketingImageUrl1 = '/img/dexamethasone-oral-solution-new.png';
export const marketingTitle1 =
  'Dexamethasone Oral Solution EU CTD dossier for licensing and/or distribution';
export const marketingDescription1 =
  'Dexamethasone  Oral solution 10mg/5ml - 50 ml, 20mg/5ml - 30 ml; New EU CTD dossier ready to file; Supply from EU GMP plant in Europe; CPP available from the European market, country of origin; Registered in European market; Stability Zones II, IVA, and IVB; Deal types: exclusive distribution or License with Supply or License without Supply (Technology transfer).';

export const marketingUrlIncludesString2 = 'manufacturer-123163';
export const marketingImageUrl2 = '/img/ezetimibe-rosuvastatin.png';
export const marketingTitle2 =
  'Ezetimibe + Rosuvastatin combination tablets new e-CTD dossier licensing';
export const marketingDescription2 =
  'Ezetimibe 10mg + Rosuvastatin 5mg, 10mg, 20mg, 40mg combination tablets new e-CTD dossier with new EU BE study reference products Crestor and Ezetrol, MA granted in Netherlands, CPP, Stability Zones II and IVB, supply from Europe.';

interface IOwnProps {
  urlPrev?: string;
  urlNext?: string;
  canonicalLinkPathname?: string;
}
export interface IProps extends RouteComponentProps<any>, IOwnProps {}

const SEOMetaData: React.FC<IProps> = ({
  match,
  location,
  urlPrev,
  urlNext,
  canonicalLinkPathname,
}) => {
  let metaTitle = 'Pipelinepharma - Best Pharmaceutical B2B Online Marketplace';
  let metaDescription =
    ' Direct connection of pharmaceutical manufacturers, distributors and wholesalers for licensing or supply agreements. Global B2B online pharma marketplace.';
  let ogImageUrl = match.path.includes(routes.fast_track_program)
    ? '/img/fast-track-program.jpg'
    : '/pp.png';
  const product =
    match.params &&
    match.params.keyword &&
    toTitleCase(
      match.params.keyword
        ? match.params.keyword
            .replace('-manufacturers', '')
            .split('-')
            .join(' ')
        : ''
    );
  const country =
    match.params && match.params.country && startCase(match.params.country);

  switch (match.path) {
    case routes.marketplace:
      // sell products
      metaTitle = 'Pharmaceutical products for sale | Pipelinepharma';
      metaDescription =
        'Create and publish your product list online to receive requests for quotation or add a competitive offer and get a signed term sheet from a qualified buyer.';
      break;
    case routes.companies:
      // assets for sale
      metaTitle =
        'Buy Pharmaceutical Companies, Assets & Business - Pipelinepharma';
      metaDescription =
        'Buy small or big pharmaceutical companies on sale online at Piplelinepharma.com. Search online from a list of pharma businesses,send request and simply buy';
      break;
    case routes.sellers_home:
      // sell products
      metaTitle = 'B2B Pharma Deal-Making Platform | Pipelinepharma';
      metaDescription =
        'Find➤ pharmaceutical products for sale worldwide.● List of products for licensing, supply and wholesale. B2B marketplace. Search✅ request✅ get offers, negotiate and buy✅ online.';
      break;
    case routes.companies_home_seller:
      // sell assets
      metaTitle =
        'Sell Pharmaceutical Company, Business & Assets | Pipelinepharma';
      metaDescription =
        'Searching➤ for a place to sell pharmaceutical business? We will offer you professional help in selling✓ pharmaceutical companies. ✉Contact us. ';
      break;
    case routes.pharmaceutical_manufacturers:
      // pharmaceutical manufacturers links list
      metaTitle =
        'Pharmaceutical manufacturers, suppliers, distributors | Pipelinepharma';
      metaDescription =
        'Looking for pharmaceutical manufacturers? Find➤ list of manufacturers, suppliers✓ distributors✓ wholesale prices of pharmaceutical products. Send request✉ accept offer or➞ negotiate online.';
      break;
    case routes.pharmaceutical_manufacturers_countries:
      // Pharmaceutical manufacturers countries (not in excel)
      metaTitle =
        'Pharmaceutical manufacturers (list by country) | Pipelinepharma';
      metaDescription =
        'Searching for pharmaceutical manufacturers online? Find➤ list of manufacturers, suppliers, distributors by country.';
      break;
    case routes.pharmaceutical_manufacturers_country:
    case routes.pharmaceutical_manufacturers_country_page:
      // Pharmaceutical manufacturers + COUNTRY NAME
      metaTitle = `Pharmaceutical manufacturers in ${country} (suppliers, distributors) | Pipelinepharma`;
      metaDescription = `Find pharmaceutical manufacturers✓ in ●${country}●. List of top✓ B2B pharmaceutical manufacturers, suppliers, wholesalers.✉ Contact us for drugs distributors in ${country}.`;
      break;
    case routes.marketplaceSearchCountry:
    case routes.marketplaceSearchCountryPage:
      // PRODUCT NAME + manufacturers in + COUNTRY NAME
      metaTitle = `${product} manufacturers, suppliers list in ${country} | Pipelinepharma`;
      metaDescription = `Find ${product} manufacturers✓ in ${country}. Global marketplace✓ for B2B. List of medication suppliers, distributors in ${country} online. Accept offer➤ or ✉send request.`;
      break;
    case routes.fast_track_program:
      metaTitle = `3 steps to secure commercial terms in 30 days! | Pipelinepharma`;
      metaDescription = `Pipelinepharma is the leading global B2B marketplace for generic pharmaceuticals helping buyers and suppliers to engage in licensing and distribution partnership.`;
      break;

    /*
    // PRODUCT NAME  + product FORM  + DOSE + manufacturers || NOT EXIST YET
    case routes.companies_home_seller:
    sell assets
    metaTitle = '';
    metaDescription = '';
    break;
*/

    case routes.company_directory:
      // pharmaceutical companies
      metaTitle = 'Pharmaceutical companies (list by country) | Pipelinepharma';
      metaDescription =
        'Searching for pharmaceutical✅ companies online? Find➤ list of companies by country. ●Top ●Largest B2B pharmaceuticals companies.';
      break;

    default:
      break;
  }
  // PRODUCT NAME + manufacturers
  if (
    match.path === routes.marketplaceSearch &&
    match.url.includes('-manufacturers')
  ) {
    metaTitle = `${product} manufacturers, suppliers, distributors | Pipelinepharma`;
    metaDescription = `Looking for ${product} manufacturers? Find➤ list of manufacturers, suppliers✓ distributors✓ wholesale prices of ${product}. Send request✉ accept offer or➞ negotiate online.`;
  }

  // pharmaceutical companies in country
  if (
    match.path.includes(routes.company_directory) &&
    match.params.country &&
    match.params.country !== ''
  ) {
    metaTitle = `Pharmaceutical companies in ${country} (list) | Pipelinepharma`;
    metaDescription = `Find list➞ of various (top, biggest) pharmaceutical companies in ${country}. Use B2B marketplace requests. Get offer✅ from pharmaceutical company located in ${country}.`;
  }

  if (location.pathname.includes(marketingUrlIncludesString1)) {
    metaTitle = marketingTitle1;
    metaDescription = marketingDescription1;
    ogImageUrl = marketingImageUrl1;
  }

  if (location.pathname.includes(marketingUrlIncludesString2)) {
    metaTitle = marketingTitle2;
    metaDescription = marketingDescription2;
    ogImageUrl = marketingImageUrl2;
  }

  // eslint-disable-next-line
  /*
  // pharmaceutical companies in city || NOT EXIST YET
  if (
    match.path.includes(routes.company_directory) &&
    match.params.city &&
    match.params.city !== ''
  ) {
    metaTitle = `Pharmaceutical companies in ${match.params.city} (list) | Pipelinepharma`;
    metaDescription = `Find list➞ of various (top, biggest) pharmaceutical companies in ${match.params.city}. Use B2B marketplace requests. Get offer✅ from pharmaceutical company located in Y.`;
  }*/

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta property="og:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />

      {urlPrev && <link rel="prev" href={`${productionRootUrl}${urlPrev}`} />}
      {urlNext && <link rel="next" href={`${productionRootUrl}${urlNext}`} />}
      {(urlPrev || urlNext) && (
        <link
          rel="canonical"
          href={`${productionRootUrl}${location.pathname}`}
        />
      )}
      {!urlPrev && !urlNext && canonicalLinkPathname && (
        <link
          rel="canonical"
          href={`${productionRootUrl}${canonicalLinkPathname}`}
        />
      )}

      <meta
        name="keywords"
        content="Pharmaceutical licensing marketplace, Pharmaceutical licensing, Pharmaceutical marketplace, Pharmaceutical products, Pharmaceutical dossiers, CTD dossier, Common Technical Document dossier, Dossiers, Licensing"
      />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.pipelinepharma.com" />
      <meta property="og:site_name" content="Pipelinepharma" />

      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:secure_url" content={ogImageUrl} />
      <link rel="shortcut icon" href="/pp.ico" />
      <link rel="icon" type="image/png" href="/pp.png" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default withRouter(SEOMetaData);
