import { GAActions, GACategory, ReactGA } from 'client/tracker';
import TextInput from 'app/components/inputs/text-input-bordered';
import ErrorNotification from 'app/components/notifications/error-oval';
import SuccessNotification from 'app/components/notifications/success-oval';
import TermOfServiceModal from 'app/modules/landings/terms-of-service-modal';
import React, { useEffect, useState } from 'react';
import { IProps } from './index';
import { logger } from '../../../utils/logger';
import Divider from './divider';
import {
  ForgetPasswordText,
  FormContentsWrapper,
  LinkedinButtonContainer,
  LinkedinIcon,
  LinkedinText,
  LoginButton,
  LoginContainer,
  LoginFormContainer,
  LoginHeader,
  LoginText,
  LoginTextLink,
  PipelinepharmaLogo,
  TermsContainer,
  TermsContainerText,
  TOS,
} from './styles';

const BASE_URL = process.env.V2_API_URL;

let win: any;

export const passwordErrorMessage = 'Password is required';
export const usernameErrorMessage = 'Username is required';

const LoginForm: React.FC<IProps> = ({
  onSignUpClick,
  onSubmit,
  clearLoginError,
  error,
  remind,
  success,
  clearLoginSuccess,
  reset,
  linkedinSuccessAction,
  setResetPasswordModalOpen,
  ...props
}) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const [message, setMessage] = useState<{
    message?: string;
    type?: 'success' | 'error';
  }>();

  const [modalOpen, setModalOpen] = useState(false);
  const [recoveryToken, setRecoveryToken] = useState('');

  try {
    if (
      typeof location !== 'undefined' &&
      typeof location !== undefined &&
      location !== undefined
    ) {
      const query = new URLSearchParams(location.search);
      const t = query.get('recovery_token');

      if (t !== undefined && t !== null && t !== '' && recoveryToken === '') {
        setRecoveryToken(t);
        setResetPasswordModalOpen(true);
      }
    }
  } catch (e) {
    logger.error('src/app/components/login-form/login-form.tsx', e);
  }

  const validate = () => {
    let valid = true;
    if (password === '') {
      setPasswordError(passwordErrorMessage);
      valid = false;
    }

    if (username === '') {
      setUsernameError(usernameErrorMessage);
      valid = false;
    }

    return valid;
  };

  const handleLoginClick = () => {
    if (validate()) {
      onSubmit({ username, password });
    }
  };

  useEffect(() => {
    if (
      !message ||
      message.type !== 'error' ||
      (message.message !== error && error !== '')
    ) {
      setMessage({ message: error, type: 'error' });
      clearLoginError();
    }
  }, [error]);

  useEffect(() => {
    if (
      !message ||
      message.type !== 'success' ||
      (message.message !== success && success !== '')
    ) {
      setMessage({ message: success, type: 'success' });
      clearLoginSuccess();
    }
  }, [success]);
  useEffect(() => {
    return () => {
      clearLoginError();
      clearLoginSuccess();
    };
  }, []);

  if (
    typeof window !== 'undefined' &&
    typeof window !== undefined &&
    window !== undefined
  ) {
    window.closeModal = (loginSuccess: boolean) => {
      if (loginSuccess) {
        win.close();
        ReactGA.event({
          category: GACategory ? GACategory.LinkedinAuth : '',
          action: GAActions ? GAActions.linkedinLoginSuccess : '',
          label: '/auth/success/linkedin',
        });
        linkedinSuccessAction();
      } else {
        ReactGA.event({
          category: GACategory ? GACategory.LinkedinAuth : '',
          action: GAActions ? GAActions.linkedinLoginFailed : '',
          label: '/auth/failed/linkedin',
        });
        setMessage({ message: 'Linkedin login failed', type: 'error' });
      }
    };
  }

  return (
    <div>
      <TermOfServiceModal
        isOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
      />

      <Container isModal={props.isModal}>
        <LoginFormContainer isModal={props.isModal}>
          <form>
            <FormContentsWrapper isModal={props.isModal}>
              <LoginHeader>Log in</LoginHeader>
              <LoginText>
                Don&apos;t have an account?{' '}
                <LoginTextLink
                  onClick={
                    props.isModal ? props.modalSingupCallback : onSignUpClick
                  }
                >
                  Create your account
                </LoginTextLink>
              </LoginText>
              {message && message.type === 'error' && message.message && (
                <ErrorNotification label={message.message} />
              )}

              {message && message.type === 'success' && message.message && (
                <SuccessNotification label={message.message} />
              )}

              <TextInput
                id={'login-username'}
                value={username}
                label={'E-mail (username)'}
                onChange={setUsername}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                error={usernameError}
              />
              <TextInput
                id={'login-password'}
                value={password}
                label={'Password'}
                onChange={setPassword}
                type={'password'}
                autocomplete={'off'}
                showPasswordIcon={true}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                error={passwordError}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    handleLoginClick();
                  }
                }}
                browserAutocompleteEnabled={true}
              />
              <ForgetPasswordText
                onClick={() => setResetPasswordModalOpen(true)}
              >
                Forgot your password?
              </ForgetPasswordText>
              <LoginButton onClick={handleLoginClick}>Log in</LoginButton>
              <Divider />
              <LinkedinButtonContainer
                onClick={() => {
                  if (window !== undefined && typeof window !== undefined) {
                    const openInNewTab = (url: string) => {
                      win = window.open(
                        url,
                        'popUpWindow',
                        'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                      );
                    };
                    openInNewTab(BASE_URL + '/auth/linkedin');
                  }
                }}
              >
                <LinkedinIcon>in</LinkedinIcon>
                <LinkedinText>Continue with Linkedin</LinkedinText>
              </LinkedinButtonContainer>
              <TermsContainer>
                <TermsContainerText>
                  By signing up you agree to Pipelinepharma’s
                  <TOS
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setModalOpen(true);
                    }}
                  >
                    Terms of Service
                  </TOS>
                </TermsContainerText>
              </TermsContainer>
            </FormContentsWrapper>
          </form>
        </LoginFormContainer>
      </Container>
    </div>
  );
};

const Container: React.FC<{ isModal?: boolean }> = ({ isModal, children }) =>
  isModal ? (
    <>{children}</>
  ) : (
    <LoginContainer>
      <PipelinepharmaLogo src={'/img/pp-logo-rebranded/logo-03.svg'} />
      {children}
    </LoginContainer>
  );

export default LoginForm;
