import CancelButton from 'app/components/buttons/cancel-button';
import SubmitButton from 'app/components/buttons/orange-button';
import TextInput from 'app/components/inputs/text-input';
import Modal from 'app/components/modals/overrided-modal';
import { media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React, { useState } from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  padding: 50px;
  margin: 15px;
  z-index: 100;
  padding-top: 0px;
  align-items: flex-start;
  ${media.max.lg`
  padding: 30px;
  margin: 5px;

  `}
  ${media.max.md`
padding: 20px;
margin: 0px;

`}
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const ForgotPasswordHeader = styled.div`
  margin-top: 26px;
  font-size: 26px;
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 30px;
`;

const ForgotPasswordText = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  max-width: 100%;
  width: 330px;
`;

const ButtonLine = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${media.max.sm`
    flex-direction: column;

  `}
`;
const ButtonDivider = styled.div`
  height: 10px;
  width: 10px;
`;

interface Props {
  submit: (email: string, password: string, recoveryToken: string) => any;
  closeModal: () => any;
  isOpen: boolean;
  recoveryTokenPassed?: string;
  recoveryEmailPassed?: string;
}

export const emailErrorMessage = 'Enter valid email address';
export const passwordErrorMessage = 'Password is required';
export const passwordTooShortErrorMessage = 'Password too short';

const RemindPasswordModal: React.FC<Props> = ({
  isOpen,
  submit,
  closeModal,
  recoveryEmailPassed,
  recoveryTokenPassed,
}) => {
  const [recoveryToken, setRecoveryToken] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState('');
  try {
    const query = new URLSearchParams(location.search);
    const t = query.get('recovery_token');
    const e = query.get('email');
    if (e !== undefined && e !== null && e !== '' && recoveryEmail === '') {
      setRecoveryEmail(e);
    }
    if (recoveryEmail === '' && recoveryEmailPassed) {
      setRecoveryEmail(recoveryEmailPassed);
    }
    if (t !== undefined && t !== null && t !== '' && recoveryToken === '') {
      setRecoveryToken(t);
    }
    if (recoveryEmail === '' && recoveryTokenPassed) {
      setRecoveryToken(recoveryTokenPassed);
    }
  } catch (e) {
    //
  }

  const checkToken =
    recoveryToken !== null &&
    recoveryToken !== undefined &&
    recoveryToken !== ''
      ? recoveryToken
      : undefined;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colors.white,
      borderWidth: '0px',
      // width: '30%',
      maxHeight: '100%',
      maxWidth: '100%',

      padding: 20,
      borderRadius: 3,
      boxShadow: colors.modalShadow,
    },
    overlay: {
      backgroundColor: colors.modalOverlay,
      zIndex: 1000,
    },
  };
  const validateEmail = (emailInner: string) => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(emailInner);
  };

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const validate = () => {
    let valid = true;
    if (
      !validateEmail(email) &&
      (recoveryToken === undefined || recoveryToken === '')
    ) {
      setEmailError(emailErrorMessage);
      valid = false;
    }
    const sixSymbols = 6;
    if (
      password === '' &&
      recoveryToken !== undefined &&
      (email === '' || !email)
    ) {
      setPasswordError(passwordErrorMessage);

      valid = false;
    } else if (
      recoveryToken !== undefined &&
      (email === '' || !email) &&
      password.length < sixSymbols
    ) {
      setPasswordError(passwordTooShortErrorMessage);
      valid = false;
    }

    return valid;
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Remind password"
      ariaHideApp={false}
    >
      <IconContainer>
        <div onClick={() => closeModal()}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <form>
          <ForgotPasswordHeader>Forgot password</ForgotPasswordHeader>
          {checkToken === undefined ? (
            <ForgotPasswordText>
              To reset your password, please enter the email address associated
              with your account.{' '}
            </ForgotPasswordText>
          ) : (
            <ForgotPasswordText>Enter new password</ForgotPasswordText>
          )}

          {checkToken === undefined ? (
            <TextInput
              id={'remind-password-confirm-email'}
              value={email}
              onChange={(text) => {
                setEmail(text);
                if (text !== '') {
                  setEmailError('');
                }
              }}
              error={emailError}
              floatingLabel={true}
              label={'Email address'}
              marginTop={'10px'}
            />
          ) : (
            <TextInput
              id={'remind-password-confirm-password'}
              value={password}
              label={'Password'}
              autocomplete={'off'}
              onChange={(text: string) => {
                setPassword(text);
                if (text !== '') {
                  setPasswordError('');
                }
              }}
              type={'password'}
              showPasswordIcon={false}
              floatingLabel={true}
              error={passwordError}
              marginTop={'10px'}
            />
          )}

          <ButtonLine>
            <SubmitButton
              onClick={() => {
                if (validate()) {
                  if (recoveryToken) {
                    submit(recoveryEmail, password, recoveryToken);
                  } else {
                    submit(email, password, recoveryToken);
                  }
                }
              }}
              label={'Submit'}
            />
            <ButtonDivider />
            <CancelButton label={'Cancel'} onClick={closeModal} />
          </ButtonLine>
        </form>
      </ModalContainer>
    </Modal>
  );
};

export default RemindPasswordModal;
