import { _, media } from 'app/styles/breakpoint';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const LoginFormContainer = styled.div<{ isModal?: boolean }>`
  ${(props) => (props.isModal ? 'margin-top: 0;' : 'margin-top: 35px;')}
  width: 510px;
  max-width: 100%;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  ${_(media.min.md)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 63px;')}
  }
  ${_(media.max.tablet)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 43px;')}
    padding-bottom: 30px;
  }
  ${_(media.max.sm)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 37px;')}
    padding-bottom: 20px;
  }

  ${media.max.xs`
 width: 100%;
`}

  ${LoginContainer} & {
    box-shadow: ${(props) =>
      props.theme.colors.boxShadowParam +
      ' ' +
      props.theme.colors.filtersShadow};
  }
`;

export const LoginHeader = styled.div`
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 3px;
  font-weight: normal;
  width: 100%;
  text-align: left;
`;

export const LoginText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
export const LoginTextLink = styled.div`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;

export const FormContentsWrapper = styled.div<{ isModal?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${_(media.max.xs)} {
    ${(props) => (props.isModal ? 'margin-top: 21px;' : 'margin-top: 41px;')}
  }
  ${(props) => (props.isModal ? 'margin-top: 41px;' : 'margin-top: 70px;')}
  padding-left: 10%;
  padding-right: 10%;
  ${_(media.min.md)} {
    ${(props) =>
      props.isModal ? 'padding-bottom: 60px;' : 'margin-bottom: 63px;'}
  }
  ${_(media.max.tablet)} {
    ${(props) =>
      props.isModal ? 'padding-bottom: 30px;' : 'margin-bottom: 43px;'}
  }
  ${_(media.max.sm)} {
    ${(props) =>
      props.isModal ? 'padding-bottom: 20px;' : 'margin-bottom: 37px;'}
  }
`;
export const ForgetPasswordText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-top: 3px;
  text-decoration: underline;
`;

export const LoginButton = styled.div`
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  width: 100%;
  margin-top: 19px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const LinkedinButtonContainer = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blueLinkedinLight};
  :hover {
    background-color: ${(props) => props.theme.colors.blueLinkedinHover};
  }
  display: flex;
  cursor: pointer;
`;

export const LinkedinIcon = styled.div`
  background-color: ${(props) => props.theme.colors.blueLinkedinDark};
  width: 50px;
  height: 50px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  border-radius: 2px;
`;

export const LinkedinText = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const TermsContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
`;

export const TermsContainerText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
`;

export const PipelinepharmaLogo = styled.img`
  width: 193px;
  height: 34px;
  object-fit: contain;
  margin-top: 36px;
`;

export const TOS = styled.span`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;
