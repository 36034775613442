import RemindPasswordModal from './remind-password-modal';

import {
  requestRemindPassword,
  requestResetPassword,
  setResetPasswordModal,
} from 'app/store/modules/auth/actions';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';

interface IStateProps {
  isOpen: boolean;
}

interface IDispatchProps {
  closeModal: () => any;
  submit: (email: string, password: string, recoveryToken: string) => any;
}

export default connect<IStateProps, IDispatchProps, any, IStore>(
  (state) => {
    return {
      isOpen: state.auth.resetPasswordModal,
    };
  },
  (dispatch) => {
    return {
      closeModal: () => dispatch(setResetPasswordModal(false)),
      submit: (email: string, password: string, recoveryToken: string) => {
        if (
          recoveryToken !== null &&
          recoveryToken !== undefined &&
          recoveryToken !== ''
        ) {
          dispatch(setResetPasswordModal(false));
          dispatch(
            requestResetPassword({
              password,
              recoveryToken,
            })
          );
        }

        if (email !== '') {
          dispatch(setResetPasswordModal(false));
          dispatch(requestRemindPassword({ email }));
        }
      },
    };
  }
)(RemindPasswordModal);
