import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const TermsOfServiceContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
`}
  ${media.max.md`
    padding-left: 42px;
    padding-right: 42px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
  `}
   ${media.max.xs`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;
const BottomBorder = styled.div`
  padding-bottom: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  width: 100%;
`;

const Header = styled.h1`
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const TextItem = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const TextItemBold = styled.h4`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-top: 30px;
  margin-bottom: 20px;
`;

const TextMaxWidth = styled.div`
  max-width: 850px;
`;

const TermsOfService: React.FC = () => {
  return (
    <TermsOfServiceContainer>
      <TextMaxWidth>
        <Header>Terms of service</Header>
        <TextItem>
          {`WHEREAS, UAB “Vitardus”, trademark “Pipelinepharma” and domain
          www.pipelinepharma.com holder, located at Vaidilutės g. 79, LT-10100,
          Vilnius, Lithuania, company code 302574281, is a global pharma
          marketplace and consultancy service provider (hereinafter - "
          Pipelinepharma", "we", "us");`}
        </TextItem>
        <TextItem>
          {`WHEREAS, you (“you” or the " User ”) are an individual or entity that
          uses Pipelinepharma services;`}
        </TextItem>
        <TextItem>
          {`WHEREAS, www.pipelinepharma.com (the " Website ") is an online global
          pharmaceutical products marketplace;`}
        </TextItem>
        <TextItem>
          {`NOW, THEREFORE, for and in consideration of the foregoing and the
          mutual covenants and agreements contained herein, the parties hereby
          stipulate, agree and enter into the agreement (the " Agreement ") as
          follows:`}
        </TextItem>

        <TextItemBold>
          General provisions and acceptance of the agreement
        </TextItemBold>

        <TextItem>
          By registering to and using the Website, you agree to be bound by and
          accept this Agreement, its terms and conditions and all the policies
          and guidelines that are incorporated by reference (for example, the
          Privacy Policy and the Cookie Policy). If you do not agree with the
          Agreement and/or individual provisions of the Agreement, you cannot
          use our services. We recommend that you store or print-off a copy of
          the Agreement (including all policies) for your records.
        </TextItem>
        <TextItem>
          This Agreement comes into effect at the moment you register at the
          Website and create your Account and is applicable for every user of
          the Website, including customers and manufacturers of pharmaceutical
          products. For the purpose of your Account creation you may be required
          to provide an additional information about you and/or your company as
          requested by Pipelinepharma.
        </TextItem>
        <TextItem>
          By creating the Account you agree and give your full consent to
          Pipelinepharma to host various information about your pharmaceutical
          products on the Website and to provide access to it for other users.
          If you do not agree to such conditions, you must inform us about it in
          writing.
        </TextItem>
        <TextItem>
          Pipelinepharma may change, amend, delete or add to this Agreement or
          any of the terms and conditions contained in any policies or rules
          governing the Website at any time and in its sole discretion. Any such
          changes will be effective upon the posting of the revised Agreement or
          such policies and rules on the Website and you are solely responsible
          for reviewing any such notice and the corresponding changes to the
          Agreement. Your continued use of the Website following any such
          revisions to the Agreement or such policies and rules will constitute
          your acceptance of such changes. If you do not agree to any such
          changes, do not continue to use our services.
        </TextItem>

        <TextItemBold> Services</TextItemBold>

        <TextItem>
          {`We provide two types of services: (1) a global pharmaceutical products
          marketplace where both customers and manufacturers of pharmaceutical
          products can interact, publish and discover products, and engage in
          business (trade) relationship; (2) consultancy services on various
          pharma related matters, including among others, mergers &
          acquisitions, marketing, business development. Pipelinepharma may from
          time to time introduce new services on the Website which shall be
          subject to this Agreement or unilaterally modify or discontinue,
          temporarily or permanently, the services (or any part thereof).`}
        </TextItem>
        <TextItem>
          Subject to the Agreement, you may access and use our services only for
          your internal business purposes as contemplated by the Agreement. You
          shall not (1) license, sublicense, sell, resell, rent, lease,
          transfer, assign, distribute, time share or otherwise commercially
          exploit or make our services and/or content available to any third
          party, other than as expressly permitted by this Agreement; (2) use
          our services to process data on behalf of any third party, or (3) use
          our services in any unlawful manner or in any manner that interferes
          with or disrupts the integrity or performance of the services and its
          components.
        </TextItem>

        <TextItemBold> Pricing</TextItemBold>

        <TextItem>
          Pipelinepharma service fees are variable. The current pricing
          schedule, fees and payment options are available at the Website. We
          reserve the right to change the pricing and fees at any time upon 30
          days notice from us, but without your consent.
        </TextItem>
        <TextItem>
          If you have chosen to upgrade to a monthly or annual payment plan, you
          will be billed monthly or annually starting on the day of the upgrade.
          You can cancel a Paid Plan at any time and you will not be billed
          again. Upon upgrading or downgrading in plan level, you will be
          credited pro rata for the time remaining in the current billing cycle.
        </TextItem>
        <TextItem>
          Pipelinepharma may offer individual service fee plans (including
          individual payment periods) to you. In this case Pipelinepharma fees
          applicable to you are determined by such individual service fee plan.
        </TextItem>
        <TextItem>
          {`Pipelinepharma's consultancy service fees are custom and are
          determined according to every individual consultancy project.`}
        </TextItem>
        <TextItem>
          Pipelinepharma will not provide any refunds of your paid fees,
          including, but not limited to, unsuccessful transaction between you
          and the manufacturer/customer.
        </TextItem>
      </TextMaxWidth>
      <BottomBorder />
    </TermsOfServiceContainer>
  );
};

export default TermsOfService;
