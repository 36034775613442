import CancelButton from 'app/components/buttons/cancel-button';
import Modal from 'app/components/modals/overrided-modal';
import { media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import styled from 'styled-components';
import TermsOfService from '../terms-of-service-form';

const ModalContainer = styled.div`
  padding: 35px;
  margin: 15px;
  z-index: 100;
  align-items: flex-start;
  overflow-y: auto;
  ${media.max.lg`
  padding: 30px;
  margin: 5px;

  `}
  ${media.max.md`
padding: 20px;
margin: 0px;

`}
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const ButtonLine = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${media.max.sm`
    flex-direction: column;

  `}
`;

interface Props {
  closeModal: () => any;
  isOpen: boolean;
}

const TermsOfServiceModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colors.white,
      overflowY: 'scroll',
      height: '90%',
      width: '1180px',
      maxWidth: '90%',
      padding: 20,
      borderWidth: '0px',
      borderRadius: 3,
      boxShadow: colors.modalShadow,
    },
    overlay: {
      backgroundColor: colors.modalOverlay,
      zIndex: 1000,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles as any}
      contentLabel="Terms of use"
      ariaHideApp={false}
    >
      <IconContainer>
        <div onClick={closeModal}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <TermsOfService />
        <ButtonLine>
          <CancelButton label={'Close'} onClick={closeModal} />
        </ButtonLine>
      </ModalContainer>
    </Modal>
  );
};

export const Components = {
  ModalContainer,
  IconContainer,
  CloseIcon,
  ButtonLine,
};
export default TermsOfServiceModal;
